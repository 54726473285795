import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import ButtonPrimary from "../components/ButtonPrimary";

const NotFoundPage = () => {
  return (
    <>
      <Layout>
        <main className="mx-auto">
          <h1 className="text-4xl">404</h1>
          <p>
            Sorry, you hit a route that does not exist.
            <br /> <br />
            <Link to="/">
              <ButtonPrimary text="Return to the homepage"></ButtonPrimary>
            </Link>
          </p>
        </main>
      </Layout>
    </>
  );
};

export default NotFoundPage;
